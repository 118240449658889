import {CurrencyModel} from "~/utils/numbersSpeller/models/Currency.model";

export const CURRENCY_VOCABULARY: CurrencyModel = {
    UAH: {
        MANY: "гривень",
        SOME: "гривні",
        ONE: "гривня",
    },
    COINS: {
        MANY: "копійок",
        SOME: "копійки",
        ONE: "копійка",
    },
};
