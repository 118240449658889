import get from "lodash.get";
import {PDF_FILED_KEYS} from "~/constants/pdf/pdfFieldKeys";
import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";
import {CLASSIFIED_ID_CHARACTERS} from "~/constants/pdf/pdfHelperConstants";
import * as CONCLUSION_OF_MONITORING_CONST from "~/config/pdf/conclusionOfMonitoringConstants";
import {PROCUREMENT_METHOD} from "~/constants/announcement/procurementMethod";
import {MILESTONE_EVENT} from "~/constants/announcement/milestone";
import {PAYMENT_TYPE} from "~/constants/announcement/paymentType";
import {DURATION_TYPE} from "~/constants/announcement/durationType";
import {PROCUREMENT_CATEGORY} from "~/constants/announcement/procurementCategory";
import {PROCUREMENT_METHOD_TYPE} from "~/constants/announcement/procurementMethodType";
import {
    AnnouncementItem,
    AnnouncementItemAdditionalClassification,
    Milestone,
} from "~/types/Announcement/AnnouncementTypes";
import {closeFrame, noAuction, noSecurement, procurementMethodTypeEU} from "~/constants/announcement/conditions";
import {AbstractDocumentStrategy} from "~/services/PDF/document/AbstractDocumentStrategy";
import {additionalClassifications} from "~/constants/announcement/additionalClassifications";
import {SignerType} from "types/sign/SignerType";
import {ANNOUNCEMENT_PAGE_MARGIN} from "~/config/pdf/announcementConstants";
import {DateHandler} from "~/utils/DateHandler";
import {ANNOUNCEMENT_TEXTS_LIST} from "~/config/pdf/texts/ANNOUNCEMENT";
import {ContactPointType} from "~/types/Tender/ContactPointType";
import {UnitHelper} from "~/services/Common/UnitHelper";
import {StringHandler} from "~/utils/StringHandler";
import {ContactPointFormatter, CONTRACT_POINT_TYPE} from "~/utils/ObjectToString/ContactPointFormatter";
import {STRING} from "~/constants/string";

export class AnnouncementDataMaker extends AbstractDocumentStrategy {
    public create(file: string, _signers: SignerType[], dictionaries: Map<string, Record<string, any>>): Record<string, any>[] {
        const parsedData = JSON.parse(file) as Record<any, any>;
        let tender: Record<string, any>;

        if (parsedData.hasOwnProperty("data")) {
            tender = parsedData.data;
        } else {
            tender = parsedData;
        }

        const {
            tenderID,
            procuringEntity,
            mainProcurementCategory,
            procurementMethod,
            procurementMethodType,
            title,
            title_en,
            lots,
            items,
        } = tender;
        const [customerCategoryUk, customerCategoryEn] = this.getCustomerCategoryWidthEn(procuringEntity, dictionaries.get("organisation"), dictionaries.get("organisation_en"), procurementMethodType);
        const isEsco = procurementMethodType === "esco";
        return [
            {
                text: ANNOUNCEMENT_TEXTS_LIST.title,
                style: PDF_FILED_KEYS.HEADING_TITLE,
            },
            this.getTitle(procurementMethod, procurementMethodType),
            {
                text: tenderID,
                style: PDF_FILED_KEYS.TITLE_MEDIUM,
            },
            this.showIfAvailable(this.getField(procuringEntity, "identifier.legalName") || this.getField(procuringEntity, "name"), ANNOUNCEMENT_TEXTS_LIST.customer_info),
            this.showIfAvailable(
                this.getField(procuringEntity, "identifier:legalName_en") ? this.getField(procuringEntity, "identifier:legalName_en") : this.getField(procuringEntity, "name_en"),
                ANNOUNCEMENT_TEXTS_LIST.purchasing_body,
                procurementMethodTypeEU.includes(procurementMethodType),
            ),
            customerCategoryUk,
            customerCategoryEn,
            this.showIfAvailable(this.getField(procuringEntity, "identifier.id"), isEsco ? ANNOUNCEMENT_TEXTS_LIST.esco_edrpou : ANNOUNCEMENT_TEXTS_LIST.customer_edrpou),
            this.showIfAvailable(
                this.getField(procuringEntity, "identifier.id"),
                ANNOUNCEMENT_TEXTS_LIST.national_id,
                procurementMethodTypeEU.includes(procurementMethodType),
            ),
            this.showIfAvailable(
                StringHandler.customerLocation(this.getField(procuringEntity, "address")),
                ANNOUNCEMENT_TEXTS_LIST.customer_location,
                Boolean(this.getField(procuringEntity, "address")),
            ),
            this.showIfAvailable(
                ContactPointFormatter.format(this.getField<ContactPointType>(procuringEntity, "contactPoint"), {
                    separator: STRING.COMMA,
                    type: CONTRACT_POINT_TYPE.NTE,
                }),
                ANNOUNCEMENT_TEXTS_LIST.customer_contact_person,
                Boolean(this.getField(procuringEntity, "contactPoint")),
            ),
            this.showIfAvailable(
                ContactPointFormatter.format(this.getField(procuringEntity, "contactPoint"), {
                    separator: STRING.COMMA,
                    type: CONTRACT_POINT_TYPE.ETE,
                }),
                ANNOUNCEMENT_TEXTS_LIST.contact_point,
                Boolean(this.getField(procuringEntity, "contactPoint")) && Boolean(procurementMethodTypeEU.includes(procurementMethodType)),
            ),
            this.showIfAvailable(
                this.getField(PROCUREMENT_CATEGORY, mainProcurementCategory),
                ANNOUNCEMENT_TEXTS_LIST.procurement_category,
                Boolean(mainProcurementCategory),
            ),
            this.showIfAvailable(
                mainProcurementCategory,
                ANNOUNCEMENT_TEXTS_LIST.main_procurement_category,
                Boolean(mainProcurementCategory) && procurementMethodTypeEU.includes(procurementMethodType),
            ),
            this.showIfAvailable(title, ANNOUNCEMENT_TEXTS_LIST.procuring_entity_title),
            this.showIfAvailable(title_en, ANNOUNCEMENT_TEXTS_LIST.title_subject, procurementMethodTypeEU.includes(procurementMethodType)),
            this.getClassificationId(items, dictionaries.get("classifier_dk"), "uk", mainProcurementCategory, procurementMethodType),
            // this.getClassificationId(items, dictionaries.get("classifier_cpv"), "en", mainProcurementCategory, procurementMethodType),
        ].concat(this.getUnderHeader(lots, tender));
    }

    public getPageMargins(): number[] {
        return ANNOUNCEMENT_PAGE_MARGIN;
    }

    private getUnderHeader(lots: Record<string, any>[], tender: Record<string, any>): Record<string, any>[] {
        return Array.isArray(lots) && lots.length > 0 ? this.displayUnderHeaderLots(tender, lots) : this.displayUnderHeader(tender);
    }

    private displayUnderHeader({
        items,
        minimalStep,
        tenderPeriod,
        auctionPeriod,
        agreementDuration,
        maxAwardsCount,
        milestones,
        value,
        guarantee,
        procurementMethodType,
        plans,
        features,
        criteria,
        awardPeriod,
        lots,
        NBUdiscountRate,
        minimalStepPercentage,
        yearlyPaymentsPercentageRange,
        fundingKind,
    }: Record<string, any>): Record<string, any>[] {
        const isEsco = procurementMethodType === "esco";
        const nbuRateConverter = 100;
        return [
            isEsco ? this.createEscoItemTable(items, minimalStepPercentage, yearlyPaymentsPercentageRange, fundingKind) : this.createItemTable(items),
            Array.isArray(milestones) ? this.createTableLayout([
                this.createTableRow({
                    head: ANNOUNCEMENT_TEXTS_LIST.contract_terms,
                    data: "",
                }),
            ]) : PDF_HELPER_CONST.EMPTY_FIELD,
            this.createPaymentTable(milestones),
            this.showIfAvailable(
                this.getField(plans, "[0].id"),
                ANNOUNCEMENT_TEXTS_LIST.plans,
                Array.isArray(plans),
            ),
            this.showIfAvailable(
                `${UnitHelper.currencyFormatting(this.getField(value, "amount"))} ${this.getField(value, "currency")}`,
                ANNOUNCEMENT_TEXTS_LIST.expected_price,
                !isEsco,
            ),
            this.showIfAvailable(
                `${UnitHelper.currencyFormatting(this.getField(minimalStep, "amount"))} ${this.getField(minimalStep, "currency")}`,
                ANNOUNCEMENT_TEXTS_LIST.minimal_step,
                !isEsco,
            ),
            this.showIfAvailable(
                features ? ANNOUNCEMENT_TEXTS_LIST.feature : ANNOUNCEMENT_TEXTS_LIST.missing_she,
                ANNOUNCEMENT_TEXTS_LIST.formula,
                !isEsco,
            ),
            this.showIfAvailable(
                `${NBUdiscountRate * nbuRateConverter}%`,
                ANNOUNCEMENT_TEXTS_LIST.nbu_discount_rate,
                isEsco,
            ),
            this.createTableLayout([
                this.createTableRow({
                    head: ANNOUNCEMENT_TEXTS_LIST.deadline,
                    data: this.getDecisionDatePublished(this.getField(tenderPeriod, "endDate"), true, true),
                }),
            ],
            ),
            this.showIfAvailable(
                this.tenderLanguageDetection(criteria).value,
                ANNOUNCEMENT_TEXTS_LIST.tender_language,
                this.tenderLanguageDetection(criteria).conditional,
            ),
            this.showIfAvailable(
                `${UnitHelper.currencyFormatting(this.getField(guarantee, "amount") || "0")} ${this.getField(guarantee, "currency")}`,
                ANNOUNCEMENT_TEXTS_LIST.securement,
                !noSecurement.includes(procurementMethodType) && !isEsco,
            ),
            this.showIfAvailable(
                this.getField<string | number>(guarantee, "amount").toString().length > 0 ? ANNOUNCEMENT_TEXTS_LIST.e_guarantee : ANNOUNCEMENT_TEXTS_LIST.missing_he,
                ANNOUNCEMENT_TEXTS_LIST.securement_type,
                !isEsco,
            ),
            this.createTableLayout([
                this.createTableRow({
                    head: ANNOUNCEMENT_TEXTS_LIST.disclosure_date,
                    data: this.getDecisionDatePublished(this.getTenderPeriod(awardPeriod, tenderPeriod, procurementMethodType), true, true),
                }),
            ]),
            this.getSecurementAmount(criteria, lots, guarantee, procurementMethodType),
            this.getSecurement(criteria, procurementMethodType),
            this.showIfAvailable(
                this.getOnlineAuction(lots, auctionPeriod),
                ANNOUNCEMENT_TEXTS_LIST.auction_date,
                !noAuction.includes(procurementMethodType) && lots,
            ),
            closeFrame.includes(procurementMethodType) ? this.createTableLayout([
                this.createTableRow({
                    head: ANNOUNCEMENT_TEXTS_LIST.frame_contact_period,
                    data: DateHandler.dateIntervalDecode(agreementDuration) || ANNOUNCEMENT_TEXTS_LIST.missing_he,
                }),
                this.createTableRow({
                    head: ANNOUNCEMENT_TEXTS_LIST.frame_contact_amount,
                    data: maxAwardsCount || ANNOUNCEMENT_TEXTS_LIST.missing_she,
                }),
            ]) : PDF_HELPER_CONST.EMPTY_FIELD,
            this.getSecurementContract(criteria, procurementMethodType),
        ];
    }

    private getTitle(procurementMethod: string, procurementMethodType: string): Record<string, any> {
        const res = this.getField(PROCUREMENT_METHOD_TYPE, `${procurementMethod}.${procurementMethodType}`);
        return {
            margin: CONCLUSION_OF_MONITORING_CONST.MARGIN_TOP_3,
            text: res ? res : this.getField(PROCUREMENT_METHOD, procurementMethod),
            style: PDF_FILED_KEYS.TITLE_MEDIUM,
        };
    }

    private createItemTable(items: Array<AnnouncementItem>): Record<string, any> {
        if (!Array.isArray(items)) {
            return PDF_HELPER_CONST.EMPTY_FIELD;
        }
        const header = [
            {
                text: ANNOUNCEMENT_TEXTS_LIST.procuring_entity_name,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.procuring_entity_code,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.procurement_amount,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.procurement_destination,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.delivery_period,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
        ];
        const body: { text: string }[][] = [];
        body.push(header);
        items.forEach(item => body.push(
            [
                {
                    text: this.getField(item, "description"),
                },
                {
                    text: this.getField(item, "classification") ?
                        `${ANNOUNCEMENT_TEXTS_LIST.dk_2015} ${this.getField(item, "classification.id")}-${this.getField(item, "classification.description")} ${this.getItemAdditionalClassification(this.getField(item, "additionalClassifications") || [])}`
                        : `${ANNOUNCEMENT_TEXTS_LIST.dk_not_set} ${this.getItemAdditionalClassification(this.getField(item, "additionalClassifications") || [])}`,
                },
                {
                    text: `${this.getField(item, "quantity")} ${this.getField(item, "unit.name")}`,
                },
                {
                    text: StringHandler.customerLocation(this.getField(item, "deliveryAddress")),
                },
                {
                    text: (this.getField(item, "deliveryDate.startDate") ? `від ${this.getDecisionDatePublished(this.getField(item, "deliveryDate.startDate"), false, true)}` : "") + `до ${this.getDecisionDatePublished(this.getField(item, "deliveryDate.endDate"), false, true)}`,
                },
            ],
        ));
        return {
            headerRows: 1,
            table: {
                dontBreakRows: true,
                widths: [
                    PDF_HELPER_CONST.ROW_WIDTH_110,
                    PDF_HELPER_CONST.ROW_WIDTH_100,
                    PDF_HELPER_CONST.ROW_WIDTH_70,
                    PDF_HELPER_CONST.ROW_WIDTH_100,
                    PDF_HELPER_CONST.ROW_WIDTH_90,
                ],
                body,
            },
        };
    }

    private createEscoItemTable(items: Array<AnnouncementItem>, minimalStepPercentage: string, yearlyPaymentsPercentageRange: string, fundingKind: string): Record<string, any> {
        if (!Array.isArray(items)) {
            return PDF_HELPER_CONST.EMPTY_FIELD;
        }
        const nbuRateConverter = 100;
        const header = [
            {
                text: ANNOUNCEMENT_TEXTS_LIST.esco_concrete_name,
                style: PDF_FILED_KEYS.TABLE_HEAD_ESCO,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.esco_classification_codes,
                style: PDF_FILED_KEYS.TABLE_HEAD_ESCO,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.procurement_destination,
                style: PDF_FILED_KEYS.TABLE_HEAD_ESCO,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.minimal_efectivity_step,
                style: PDF_FILED_KEYS.TABLE_HEAD_ESCO,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.other_criterias,
                style: PDF_FILED_KEYS.TABLE_HEAD_ESCO,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.esco_financing,
                style: PDF_FILED_KEYS.TABLE_HEAD_ESCO,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.max_fixed_percent,
                style: PDF_FILED_KEYS.TABLE_HEAD_ESCO,
            },
        ];
        const body: { text: string; style: string }[][] = [];
        body.push(header);
        let fundingKindCell = "";
        switch (fundingKind) {
            case "other":
                fundingKindCell = ANNOUNCEMENT_TEXTS_LIST.esco_funding_other;
                break;
            case "budget":
                fundingKindCell = ANNOUNCEMENT_TEXTS_LIST.esco_funding_budget;
                break;
        }
        items.forEach(item => body.push(
            [
                {
                    text: this.getField(item, "description"),
                    style: PDF_FILED_KEYS.TABLE_DATA_ESCO,
                },
                {
                    text: this.getField(item, "classification") ?
                        `${ANNOUNCEMENT_TEXTS_LIST.dk_2015} ${this.getField(item, "classification.id")}-${this.getField(item, "classification.description")} ${this.getItemAdditionalClassification(this.getField(item, "additionalClassifications") || [])}`
                        : `${ANNOUNCEMENT_TEXTS_LIST.dk_not_set} ${this.getItemAdditionalClassification(this.getField(item, "additionalClassifications") || [])}`,
                    style: PDF_FILED_KEYS.TABLE_DATA_ESCO,
                },
                {
                    text: StringHandler.customerLocation(this.getField(item, "deliveryAddress")),
                    style: PDF_FILED_KEYS.TABLE_DATA_ESCO,
                },
                {
                    text: String(Number(minimalStepPercentage) * nbuRateConverter),
                    style: PDF_FILED_KEYS.TABLE_DATA_ESCO,
                },
                {
                    text: "",
                    style: PDF_FILED_KEYS.TABLE_DATA_ESCO,
                },
                {
                    text: fundingKindCell,
                    style: PDF_FILED_KEYS.TABLE_DATA_ESCO,
                },
                {
                    text: yearlyPaymentsPercentageRange,
                    style: PDF_FILED_KEYS.TABLE_DATA_ESCO,
                },
            ],
        ));
        return {
            headerRows: 1,
            table: {
                widths: [
                    PDF_HELPER_CONST.ROW_WIDTH_65,
                    PDF_HELPER_CONST.ROW_WIDTH_65,
                    PDF_HELPER_CONST.ROW_WIDTH_65,
                    PDF_HELPER_CONST.ROW_WIDTH_75,
                    PDF_HELPER_CONST.ROW_WIDTH_70,
                    PDF_HELPER_CONST.ROW_WIDTH_65,
                    PDF_HELPER_CONST.ROW_WIDTH_65,
                ],
                body,
            },
        };
    }

    private createPaymentTable(milestones: Array<Milestone>): Record<string, any> {
        if (!Array.isArray(milestones)) {
            return PDF_HELPER_CONST.EMPTY_FIELD;
        }
        const header = [
            {
                text: ANNOUNCEMENT_TEXTS_LIST.contract_terms_event,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.contract_terms_description,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.contract_terms_paymentType,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.contract_terms_period,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.contract_terms_daysType,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: ANNOUNCEMENT_TEXTS_LIST.contract_terms_payment,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
        ];
        const body: { text: string }[][] = [];
        body.push(header);
        milestones.forEach((milestone: Record<string, any>) => body.push(
            [
                {
                    text: this.getField(MILESTONE_EVENT, this.getField(milestone, "title")),
                },
                {
                    text: this.getField(milestone, "description"),
                },
                {
                    text: this.getField(PAYMENT_TYPE, this.getField(milestone, "code")),
                },
                {
                    text: this.getField(milestone, "duration.days"),
                },
                {
                    text: this.getField(DURATION_TYPE, this.getField(milestone, "duration.type")),
                },
                {
                    text: this.getField(milestone, "percentage"),
                },
            ],
        ));
        return {
            table: {
                widths: [
                    PDF_HELPER_CONST.ROW_WIDTH_70,
                    PDF_HELPER_CONST.ROW_WIDTH_150,
                    PDF_HELPER_CONST.ROW_WIDTH_90,
                    PDF_HELPER_CONST.ROW_WIDTH_50,
                    PDF_HELPER_CONST.ROW_WIDTH_50,
                    PDF_HELPER_CONST.ROW_WIDTH_50,
                ],
                body,
            },
        };
    }

    private getCustomerCategoryWidthEn(
        procuringEntity: Record<string, any>,
        organisationDictionary_uk: Record<string, any> | undefined,
        organisationDictionary_en: Record<string, any> | undefined,
        procurementMethodType: string,
    ): Record<string, any>[] {
        if (organisationDictionary_uk === undefined || organisationDictionary_en === undefined) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }
        const entityKindTranslatedUK = this.getField<string>(organisationDictionary_uk, `${this.getField(procuringEntity, "kind")}.title`);
        const entityKindTranslatedEN = this.getField<string>(organisationDictionary_en, `${this.getField(procuringEntity, "kind")}.title`);

        return [
            entityKindTranslatedUK ? this.createTableLayout([
                this.createTableRow({
                    head: ANNOUNCEMENT_TEXTS_LIST.customer_category,
                    data: entityKindTranslatedUK,
                }),
            ]) : PDF_HELPER_CONST.EMPTY_FIELD,
            entityKindTranslatedEN && procurementMethodTypeEU.includes(procurementMethodType) ? this.createTableLayout([
                this.createTableRow({
                    head: ANNOUNCEMENT_TEXTS_LIST.kind,
                    data: entityKindTranslatedEN,
                }),
            ]) : PDF_HELPER_CONST.EMPTY_FIELD,
        ];
    }

    private getClassificationId(items: Record<string, any>[], classificationDictionary: Record<string, any> | undefined, lang: string, mainProcurementCategory: string, procurementMethodType: string): Record<string, any> {
        if (lang !== "uk" && !procurementMethodTypeEU.includes(procurementMethodType)) {
            return PDF_HELPER_CONST.EMPTY_FIELD;
        }

        if (mainProcurementCategory === "works" || !Array.isArray(items) || classificationDictionary === undefined) {
            return PDF_HELPER_CONST.EMPTY_FIELD;
        }

        let classifiedId = this.getField<string>(items[0], "classification.id");
        let classifiedKey = this.getField<string>(items[0], "classification.id");

        try {
            switch (true) {
                case classifiedId === "99999999-9":
                    classifiedKey = "99999999-9";
                    classifiedId = this.getField(classificationDictionary, "99999999-9");
                    break;
                case classifiedId.indexOf("336") === 0:
                    classifiedKey = "33600000-6";
                    classifiedId = this.getField(classificationDictionary, "33600000-6");
                    break;
                default:
                    [classifiedKey] = Object.keys(classificationDictionary).filter(item => item.startsWith(classifiedId.slice(0, CLASSIFIED_ID_CHARACTERS).toString()));
                    classifiedId = this.getField(classificationDictionary, classifiedKey);
            }
        } catch (e) {
            console.log(e);
        }

        return classifiedId ? this.createTableLayout([
            this.createTableRow({
                head: lang === "uk" ? ANNOUNCEMENT_TEXTS_LIST.purchase_dictionary_code : ANNOUNCEMENT_TEXTS_LIST.cpv,
                data: lang === "uk" ? `${ANNOUNCEMENT_TEXTS_LIST.dk_2015}:${classifiedKey}: ${classifiedId}` : `${ANNOUNCEMENT_TEXTS_LIST.dk_2015_en}: ${classifiedId}`,
            }),
        ]) : PDF_HELPER_CONST.EMPTY_FIELD;
    }

    private getItemAdditionalClassification(classifications: AnnouncementItemAdditionalClassification[]): string {
        if (!Array.isArray(classifications)) {
            return "";
        }

        let res = "";
        const classificationATC = classifications.filter(item => item.scheme === "ATC");
        const classificationINN = classifications.filter(item => item.scheme === "INN");

        if (classificationATC || classificationINN) {
            res = `${this.getField(classificationINN[0], "title")} ${this.getField(classificationATC[0], "title")}`;
        } else {
            const resClassifications = classifications.filter(item => item.scheme && item.id && item.id !== "000");
            resClassifications.map(item => res = `${res} ${this.getField(additionalClassifications, item.scheme)} ${item.id}-${item.description}`);
        }
        return res;
    }

    private getTenderPeriod(awardPeriod: Record<string, any>, tenderPeriod: Record<string, any>, procurementMethodType: string): string {
        if (noSecurement.includes(procurementMethodType) && this.getField(awardPeriod, "startDate")) {
            return this.getField(awardPeriod, "startDate");
        }
        return this.getField(tenderPeriod, "endDate") ? this.getField(tenderPeriod, "endDate") : ANNOUNCEMENT_TEXTS_LIST.missing_she;
    }

    private getOnlineAuction(lots: Record<string, any>[], auctionPeriod: Record<string, any>): string {
        if (Array.isArray(lots) && lots.length > 0) {
            return this.getField(lots[0], "auctionPeriod.startDate") ? this.getDecisionDatePublished(this.getField(lots[0], "auctionPeriod.startDate"), true) : ANNOUNCEMENT_TEXTS_LIST.missing_he;
        }
        return this.getField(auctionPeriod, "startDate") ? this.getDecisionDatePublished(this.getField(auctionPeriod, "startDate"), true) : ANNOUNCEMENT_TEXTS_LIST.missing_he;
    }

    private displayUnderHeaderLots(file: Record<string, any>, lots: Record<string, any>[]): Record<string, any>[] {
        const res: Record<string, any>[] = [];

        lots.map((lot, index) => {
            res.push({
                text: `Лот ${index + 1} — ${lot.title}`,
                style: PDF_FILED_KEYS.TITLE_LARGE,
            });
            const {items, milestones} = file;
            const lotFile: Record<string, any> = {...file};
            lotFile.items = Array.isArray(items) ? items.filter((item: {
                relatedLot: string
            }) => item.relatedLot === lot.id) : [];
            lotFile.milestones = Array.isArray(milestones) ? milestones.filter((item: {
                relatedLot: string
            }) => item.relatedLot === lot.id) : [];
            lotFile.value = lot.value;
            lotFile.minimalStep = lot.minimalStep;
            lotFile.auctionPeriod = lot.auctionPeriod;
            lotFile.guarantee = lot.guarantee;
            res.push(this.displayUnderHeader(lotFile));
        });
        return res;
    }

    private getSecurement(criteria: Record<string, any>[], procurementMethodType: string): Record<string, any> {
        return this.showIfAvailable(
            Array.isArray(criteria) ?
                this.getField(
                    criteria.filter((item: Record<string, any>) => this.getField(item, "classification.id") === ANNOUNCEMENT_TEXTS_LIST.criterion_guarantee),
                    "[0].requirementGroups[0].requirements[0].description",
                ) || ANNOUNCEMENT_TEXTS_LIST.missing_they
                : ANNOUNCEMENT_TEXTS_LIST.missing_they,
            procurementMethodType === "belowThreshold" ? ANNOUNCEMENT_TEXTS_LIST.securement_conditions : ANNOUNCEMENT_TEXTS_LIST.securement_type_conditions,
            noSecurement.includes(procurementMethodType),
        );
    }

    private getSecurementAmount(criteria: Record<string, any>[], lots: Record<string, any>[], guarantee: Record<string, any>, procurementMethodType: string): Record<string, any> {
        if (!Array.isArray(criteria)) {
            return PDF_HELPER_CONST.EMPTY_FIELD;
        }

        const guaranteeAmount = guarantee && Number(this.getField(guarantee, "amount")) > 0 ? `${UnitHelper.currencyFormatting(this.getField(guarantee, "amount"))} ${this.getField(guarantee, "currency")}` : ANNOUNCEMENT_TEXTS_LIST.missing_he;
        const lotsGuaranteeAmount = Number(this.getField(lots, "[0].guarantee.amount")) > 0 ? `${UnitHelper.currencyFormatting(this.getField(lots, "[0].guarantee.amount"))} ${this.getField(lots, "[0].guarantee.currency")}` : ANNOUNCEMENT_TEXTS_LIST.missing_he;
        return this.showIfAvailable(
            Array.isArray(lots) && lots.length > 0 ? lotsGuaranteeAmount : guaranteeAmount,
            ANNOUNCEMENT_TEXTS_LIST.securement_amount,
            Array.isArray(criteria) && noSecurement.includes(procurementMethodType),
        );
    }

    private getSecurementContract(criteria: Record<string, any>[], procurementMethodType: string): Record<string, any> {
        if (!Array.isArray(criteria)) {
            return PDF_HELPER_CONST.EMPTY_FIELD;
        }

        const filteredCriterias = criteria.filter(
            (item: Record<string, any>) => this.getField(item, "classification.id") === ANNOUNCEMENT_TEXTS_LIST.contract_guarantee,
        );

        if (!Array.isArray(filteredCriterias)) {
            return PDF_HELPER_CONST.EMPTY_FIELD;
        }

        const requirements = get(
            filteredCriterias,
            "[0].requirementGroups[0].requirements",
        );

        if (!Array.isArray(requirements)) {
            return PDF_HELPER_CONST.EMPTY_FIELD;
        }

        const filteredRequirements = requirements.filter(
            (item: Record<string, any>) => this.getField(item, "status") === "active",
        );

        return noSecurement.includes(procurementMethodType) ?
            this.createTableLayout(
                [
                    this.createTableRow({
                        head: ANNOUNCEMENT_TEXTS_LIST.securement_contract,
                        data: this.getField(filteredRequirements, "[0].description") || ANNOUNCEMENT_TEXTS_LIST.missing_they,
                    }),
                ],
                false,
            )
            : PDF_HELPER_CONST.EMPTY_FIELD;
    }

    private tenderLanguageDetection(criteria: Array<any>): { conditional: boolean; value: string } {
        if (!Array.isArray(criteria)) {
            return {conditional: false, value: ""};
        }
        const filtered_criteria = criteria.filter(item => this.getField(item, "classification.id") === ANNOUNCEMENT_TEXTS_LIST.criterion_language);
        const value = this.getField<string>(filtered_criteria, "[0].requirementGroups[0].requirements[0].title").includes("українська") ? "українська" : "інша";
        return {conditional: filtered_criteria.length > 0, value};
    }
}
