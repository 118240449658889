import axios, {AxiosResponse} from "axios";
import {TenderOfferType} from "~/types/TenderOffer/Tender";
import {PQContractType, PQDataComplexType} from "~/widgets/pq/types/PQTypes";
import {apiTendersUrl} from "~/config/pdf/apiUrl";

export class FetchTender {
    static async load(tenderID: string): Promise<TenderOfferType> {
        if (!tenderID) {
            return {} as TenderOfferType;
        }
        const url = `${apiTendersUrl}/${tenderID}`;
        try {
            const {data: {data}}: AxiosResponse = await axios.get(url);
            return data as TenderOfferType;
        } catch (e) {
            return {} as TenderOfferType;
        }
    }

    static async getTenderForContract(contract: PQContractType): Promise<PQDataComplexType> {
        const tender = await this.load(contract?.tender_id);

        return {
            tender,
            contract,
        };
    }
}
