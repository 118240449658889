import {AbstractDocumentStrategy} from "~/services/PDF/document/AbstractDocumentStrategy";
import {STRING} from "~/constants/string";
import {AnnouncementItem} from "~/types/Announcement/AnnouncementTypes";
import {PRICE_DECIMAL_PRECISION} from "~/constants/pdf/pdfHelperConstants";

export class UnitHelper {
    private readonly strategy: AbstractDocumentStrategy;

    constructor(strategy: AbstractDocumentStrategy) {
        this.strategy = strategy;
    }

    static currencyFormatting(currency: string|number): string {
        // 483500 -> 483 500,00
        const [full = STRING.EMPTY, decimal = STRING.EMPTY] = currency.toString().split(STRING.DOT);
        const decimal_part = UnitHelper.coinsFormatting(decimal);

        return [full.replace(/\B(?=(\d{3})+(?!\d))/g, STRING.WHITESPACE), decimal_part].join(STRING.COMMA);
    }

    static coinsFormatting(decimal: string): string {
        switch (true) {
            case decimal.length > 1:
                return Number(`0.${decimal}`).toFixed(PRICE_DECIMAL_PRECISION).slice(PRICE_DECIMAL_PRECISION);

            case decimal.length === 1:
                return `${decimal}0`;

            default:
                return "00";
        }
    }

    public prepareUnitName(item: AnnouncementItem, recommendedDictionary: Record<string, any> | undefined): string {
        if (!this.strategy.emptyChecker.isEmptyString(this.strategy.getField(item, "unit.name"))) {
            return this.strategy.getField(item, "unit.name");
        }
        if (recommendedDictionary === undefined) {
            return STRING.DASH;
        }
        const unitDictionaryName = this.strategy.getField(recommendedDictionary, `${this.strategy.getField(item, "unit.code", "")}.name`, "");
        return this.strategy.emptyChecker.isEmptyString(unitDictionaryName) ? STRING.DASH : unitDictionaryName;
    }
}
