import {TemplateCodesEnum} from "~/widgets/pq/types/TemplateCodes.enum";
import {foodContractTitles} from "~/widgets/pq/templates/food/configs/foodContractTitles";
import {pqMedicine2Titles} from "~/widgets/pq/templates/medicine2/config/medicineV2titlesConfig";
import {genericContractTitles} from "~/widgets/pq/templates/generic/configs/genericContractTitles";
import {fuelContractTitles} from "~/widgets/pq/templates/fuel/configs/fuelContractTitles";
import {pqGeneralTitles, pqMedicineTitles} from "~/widgets/pq/configs/pqTitles";
import {pharm2Titles} from "~/widgets/pq/templates/pharm2/configs/pharm2Title.config";

export const TemplateToPqTitlesMap = new Map<TemplateCodesEnum, Record<string, string>>()
    .set(TemplateCodesEnum.FRUIT, pqGeneralTitles)
    .set(TemplateCodesEnum.COMPUTER, pqGeneralTitles)
    .set(TemplateCodesEnum.OTHER, pqGeneralTitles)
    .set(TemplateCodesEnum.MEDICINE, pqMedicineTitles)
    .set(TemplateCodesEnum.PHARM, pqMedicineTitles)
    .set(TemplateCodesEnum.PHARM2, pharm2Titles)
    .set(TemplateCodesEnum.GAS, fuelContractTitles)
    .set(TemplateCodesEnum.GENERIC, genericContractTitles)
    .set(TemplateCodesEnum.MEDICINE2, pqMedicine2Titles)
    .set(TemplateCodesEnum.FOOD, foodContractTitles);
