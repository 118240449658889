import {PDFTableBodyType, TableConfigType, TableWidthType} from "~/widgets/pq/types/TextConfigType";
import {STRING} from "~/constants/string";
import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";
import {PDF_FILED_KEYS} from "~/constants/pdf/pdfFieldKeys";

export class PDFTablesGenerator {
    static createTable(
        body: PDFTableBodyType = [[STRING.EMPTY]],
        widths: TableWidthType = [PDF_HELPER_CONST.ROW_AUTO_WIDTH],
        style = PDF_FILED_KEYS.TABLE_DATA,
        heights = PDF_HELPER_CONST.LINE_HEIGHT_40,
        headerRows = 1,
    ): TableConfigType {
        return {
            headerRows,
            style,
            table: {
                dontBreakRows: true,
                heights,
                widths,
                body,
            },
        };
    }
}
