import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";
import {AbstractDocumentStrategy} from "~/services/PDF/document/AbstractDocumentStrategy";
import {SignerType} from "types/sign/SignerType";
import {
    DEFAULT_PAGE_MARGIN,
} from "~/config/pdf/announcementConstants";
import {
    PQContractType, PQDataComplexType,
} from "~/widgets/pq/types/PQTypes";
import {STRING} from "~/constants/string";
import {TemplateCodesEnum} from "~/widgets/pq/types/TemplateCodes.enum";
import {TenderOfferType} from "~/types/TenderOffer/Tender";
import {TEMPLATE_TO_BUILDER} from "~/widgets/pq/configs/TemplateToBuilder.map";
import {TemplateCodeChecker} from "~/widgets/pq/utils/TemplateCodeChecker";

export class PQDataMaker extends AbstractDocumentStrategy {
    private _tender: TenderOfferType | Record<any, any> = {};

    create(
        contractTemplateName: string,
        _signers?: SignerType[],
        _dictionaries?: Map<string, Record<string, any>>,
        contract?: PQDataComplexType | Record<string, any>,
    ): Record<string, any>[] {
        this._tender = contract?.tender || {};
        const contractObject: PQContractType | Record<string, never> = contract?.contract || {contractTemplateName: STRING.EMPTY};
        const contractTemplate: TemplateCodesEnum = TemplateCodeChecker.validatedTemplateName(contractObject.contractTemplateName || contractTemplateName);

        if (TEMPLATE_TO_BUILDER.get(contractTemplate)) {
            return TEMPLATE_TO_BUILDER.get(contractTemplate)?.build(contractObject, contractTemplate, this._tender) || [];
        }

        return [];
    }

    createFooter(): Record<string, any>[] {
        return [PDF_HELPER_CONST.EMPTY_FIELD];
    }

    getPageMargins(): number[] {
        return DEFAULT_PAGE_MARGIN;
    }
}
