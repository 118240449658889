import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";

export const PQ_GENERIC_TABLE_ITEMS_WIDTH = [
    PDF_HELPER_CONST.ROW_WIDTH_150,
    PDF_HELPER_CONST.ROW_WIDTH_65,
    PDF_HELPER_CONST.ROW_WIDTH_65,
    PDF_HELPER_CONST.ROW_WIDTH_90,
    PDF_HELPER_CONST.ROW_WIDTH_95,
];

export const PQ_GENERIC_TABLE_ADDRESS_WIDTH = [
    PDF_HELPER_CONST.ROW_WIDTH_150,
    PDF_HELPER_CONST.ROW_WIDTH_340,
];

export const PQ_GENERIC_TABLE_ATTRIBUTES_WIDTH = [
    PDF_HELPER_CONST.ROW_WIDTH_245,
    PDF_HELPER_CONST.ROW_WIDTH_245,
];

export const PQ_TABLE_ITEMS_WIDTH = [
    PDF_HELPER_CONST.ROW_WIDTH_100,
    PDF_HELPER_CONST.ROW_WIDTH_100,
    PDF_HELPER_CONST.ROW_WIDTH_70,
    PDF_HELPER_CONST.ROW_WIDTH_70,
    PDF_HELPER_CONST.ROW_WIDTH_70,
    PDF_HELPER_CONST.ROW_WIDTH_70,
];
