import {TextConfigType} from "~/widgets/pq/types/TextConfigType";
import {TemplateCodesEnum} from "~/widgets/pq/types/TemplateCodes.enum";
import {fruitTextsConfig} from "~/widgets/pq/templates/fruit/configs/fruitTextsConfig";
import {computerTextsConfig} from "~/widgets/pq/templates/computers/configs/computersTextsConfig";
import {fuelTextsConfig} from "~/widgets/pq/templates/fuel/configs/fuelTextsConfig";
import {pharmTextsConfig} from "~/widgets/pq/templates/pharm/configs/pharmTextsConfig";
import {medicineTextsConfig} from "~/widgets/pq/templates/medicine/configs/medicineTextsConfig";
import {genericTextsConfig} from "~/widgets/pq/templates/generic/configs/genericTextsConfig";
import {foodTextsConfig} from "~/widgets/pq/templates/food/configs/foodTextsConfig";
import {pharm2ContractConfig} from "~/widgets/pq/templates/pharm2/configs/pharm2Contract.config";
import {medicine2TextsConfig} from "~/widgets/pq/templates/medicine2/config/medicineV2Contract.config";

export const TemplateToTextMap = new Map<string, TextConfigType>()
    .set(TemplateCodesEnum.FRUIT, fruitTextsConfig)
    .set(TemplateCodesEnum.COMPUTER, computerTextsConfig)
    .set(TemplateCodesEnum.OTHER, computerTextsConfig)
    .set(TemplateCodesEnum.MEDICINE, medicineTextsConfig)
    .set(TemplateCodesEnum.PHARM, pharmTextsConfig)
    .set(TemplateCodesEnum.GAS, fuelTextsConfig)
    .set(TemplateCodesEnum.GENERIC, genericTextsConfig)
    .set(TemplateCodesEnum.FOOD, foodTextsConfig)
    .set(TemplateCodesEnum.MEDICINE2, medicine2TextsConfig)
    .set(TemplateCodesEnum.PHARM2, pharm2ContractConfig);
