import {
    CriterionType,
    ItemType, RequirementGroupType,
    RequirementResponseType,
    SubCriteriaFieldsType,
    UnitType,
} from "~/types/TenderOffer/Tender";
import {EmptyChecker} from "~/utils/checker/EmptyChecker";
import {STRING} from "~/constants/string";

export class CriteriaTransformer {
    static getSpecificationsFieldsTable(item: ItemType, criteria: CriterionType[] | undefined, requirementResponses: RequirementResponseType[] | undefined): SubCriteriaFieldsType[] | null {
        const emptyChecker = new EmptyChecker();
        if (emptyChecker.isEmptyArray(requirementResponses) || !criteria || emptyChecker.isEmptyArray(criteria)) {
            return null;
        }

        const subCriteriaFields = this.getSubCriteriaFields(item, criteria, requirementResponses as RequirementResponseType[]);
        return emptyChecker.isNotEmptyArray(subCriteriaFields) ? subCriteriaFields : null;
    }

    static getSubCriteriaFields({id}: ItemType, criteria: CriterionType[], requirementResponses: RequirementResponseType[]): SubCriteriaFieldsType[] {
        const emptyChecker = new EmptyChecker();
        const itemCriteria = criteria.filter(criterion => criterion.relatedItem === id);

        return emptyChecker.isEmptyArray(itemCriteria) ? [] : itemCriteria.reduce((criteriaFields, criterion) => {
            const {requirementGroups} = criterion;
            const criterionRequirements: Record<string, {
                title: string
                unit?: UnitType
            }> = this.criterionRequirements(requirementGroups);
            const filteredResponses = requirementResponses.filter(response =>
                CriteriaTransformer.bidRequirementIds(requirementGroups).includes(response.requirement.id)
                && (response.relatedItem === id || !response.relatedItem),
            );
            const requirements = this.requirementGroupsRequirements(filteredResponses);

            Object.keys(criterionRequirements).forEach(key => {
                if (Object.keys(requirements).includes(key)) {
                    criteriaFields.push({
                        classificationId: criterion.classification?.id || STRING.EMPTY,
                        title: criterionRequirements[key].title,
                        unit: criterionRequirements[key].unit,
                        value: requirements[key].value,
                        values: requirements[key].values,
                    });
                }
            });
            return criteriaFields;
        }, [] as SubCriteriaFieldsType[]);
    }

    static criterionRequirements(requirementGroups: RequirementGroupType[]): Record<string, {
        title: string
        unit?: UnitType
    }> {
        return requirementGroups.reduce((criterionRequirements, group) => {
            group.requirements.forEach(requirement => {
                criterionRequirements[requirement.id] = {title: requirement.title, unit: requirement.unit};
            });
            return criterionRequirements;
        }, {} as Record<string, { title: string; unit?: UnitType }>);
    }

    static requirementGroupsRequirements(requirementResponses: RequirementResponseType[]): Record<string, {
        value?: string
        values?: string[]
    }> {
        return requirementResponses.reduce((accum, response) => {
            accum[response.requirement.id] = {
                value: String(response.value),
                values: response.values,
            };
            return accum;
        }, {} as Record<string, { value?: string; values?: string[] }>);
    }

    static bidRequirementIds(requirementGroups: RequirementGroupType[]): string[] {
        return requirementGroups.reduce((acc, requirementResponses) =>
            acc.concat(...CriteriaTransformer.requirementResponsesRequirementsIds(requirementResponses)),
        [] as string[],
        ) || [];
    }

    static requirementResponsesRequirementsIds(requirementResponses: RequirementGroupType): string[] {
        return requirementResponses.requirements.reduce(
            (requirementAcc, {id}) => requirementAcc.concat(id),
            [] as string[],
        );
    }
}
