import {ClassificationType} from "~/types/Tender/ClassificationType";
import {STRING} from "~/constants/string";
import get from "lodash.get";

export class ClassificationTransformer {
    static formatClassification(classification: ClassificationType): string {
        return [
            get(classification, "scheme"),
            get(classification, "id"),
            get(classification, "description"),
        ].join(STRING.WHITESPACE);
    }
}
