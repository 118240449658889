planningApp.controller('planningEditCtrl', function (
    $scope,
    $stateParams,
    $state,
    $rootScope,
    $timeout,
    classifierService,
    planningService,
    planData,
    tenderService,
    dateListService,
    unitsService,
    ntFlash,
    validateService,
    formationService,
    uibDatepickerConfig,
    currencyListService,
    oraCodesService,
    OPC_CONFIG,
) {
    ntFlash.clean();

    $scope.OPC_CREATE_PLAN_LEGACY = OPC_CONFIG.OPC_CREATE_PLAN_LEGACY;  // Remove legacy after 20.12.2018

    $scope.codes = [];
    oraCodesService.getCodesByCategory('UA')
        .then(c => $scope.codes = c);

    uibDatepickerConfig.minDate = null;

    $scope.isCreateMode = false;

    $scope.plan = planData.data.response;

    console.log('plan', $scope.plan);

    $scope.isCzo = $scope.plan.tender.procurementMethodType === 'centralizedProcurement';
    $scope.hideAddress = !($scope.plan.buyers[0].address.locality);
    $scope.wasCzo = $scope.isCzo;
    $scope.czoList = [];
    $scope.czoSelected = $scope.plan.procuringEntity.identifier.id;
    $scope.isDream = $scope.plan.project ? true : false

    $scope.plan.procuringEntity = angular.copy(OPC_CONFIG.PROCURING_ENTITY);
    delete $scope.plan.procuringEntity.contactPoint;

    const cacheProcurementMethodType = $scope.plan.tender.procurementMethodType;
    const cacheProcuringEntity = $scope.plan.procuringEntity;

    $scope.plan.budget.TaxIncluded = true;

    if ($scope.plan.budget.breakdown === undefined) {
        $scope.plan.budget.breakdown = [{
            description: "",
            title: "",
            value: {
                amount: null,
                currency: ""
            }
        }];
    }

    $scope.currencies = currencyListService.getCurrenciesList();

    $scope.currentDate = new Date();

    if (!$scope.plan.additionalClassifications) {
        $scope.plan.additionalClassifications = [];
    }

    if ($scope.plan.items) {
        $scope.plan.items.forEach(function (item) {
            item.unit.name = gettext('единицы измерения');

            if (!item.deliveryDate) {
                item.deliveryDate = {};
            }

            item.deliveryDate.endDateIsOpen = false;
            item.deliveryDate.endDate = item.deliveryDate.endDate == null ? null : new Date(item.deliveryDate.endDate);

            item.classification.data = item.classification;

            if (!item.additionalClassifications) {
                item.additionalClassifications = [];
            }
        });
    }

    unitsService.get()
        .then(function (data) {
            $scope.units = data;
        });

    $scope.open = function ($event, obj, string) {
        $event.preventDefault();
        $event.stopPropagation();

        obj[string + 'IsOpen'] = true;
    };

    $scope.selectDropdown = function (item, code) {
        item.unit.name = code.name;
        item.unit.code = code.code;
    };

    $scope.clearClassificator = function (type, index) {
        $scope.setCurrentClassifierIndex(index);
        classifierService.clearItemTypeClassification($scope.currentClassifierIndex, type);
    };

    $scope.clear = function (target) {
        target.field = "";
        target.data = [];
    };

    $scope.setCurrentClassifierIndex = function (index) {
        if (index === null || index === undefined) {
            $scope.currentClassifierIndex = $scope.plan;
        } else {
            $scope.currentClassifierIndex = $scope.plan.items[index];
        }
    };

    $scope.openClassifier = function (type, index) {
        $scope.setCurrentClassifierIndex(index);
        let codes, checked = [];
        let route = `planning.edit.${type}`;
        if (type === 'cpv') {
            if ($scope.currentClassifierIndex.classification.id) {
                checked = [$scope.currentClassifierIndex.classification.id];
            }

        } else {
            let dkSet = classifierService.getGroupSchemeByType(type);
            let additionalClassifications = ($scope.currentClassifierIndex.additionalClassifications || []).filter((cl) => {
                return dkSet.has(cl.scheme);
            });
            checked = additionalClassifications.map((cl) => {
                return cl.id;
            });

            if (type === 'inn') {
                codes = classifierService.filterSchemes(
                    $scope.currentClassifierIndex.additionalClassifications, ['ATC']).map(
                    (cl) => {
                        return cl.id;
                    })
            } else if (type === 'atc') {
                codes = classifierService.filterSchemes(
                    $scope.currentClassifierIndex.additionalClassifications, ['INN']).map(
                    (cl) => {
                        return cl.id;
                    })
            }
        }
        $state.go(route, {
            checked: checked,
            codes,
        });
    };

    $scope.isMOZ = function (item) {
        return classifierService.isMOZ(item);
    };

    $scope.isINNRequired = function (item) {
        return classifierService.isINNRequired(item);
    };

    $rootScope.$on('addClasificators', function (event, data, type) {
        if ($scope.currentClassifierIndex) {
            let scheme = classifierService.getSchemeByType(type);
            classifierService.clearItemClassification($scope.currentClassifierIndex, scheme);
            classifierService.updateItemClassification($scope.currentClassifierIndex, data);
            $state.go('planning.edit');
        }
    });

    $rootScope.$on('closeClasificators', function () {
        $state.go('planning.edit');
    });

    $scope.addField = function () {
        if ($scope.plan.items === undefined) {
            $scope.plan.items = [];
        }
        $scope.plan.items.push({
            "deliveryDate": {
                "endDate": ""
            },
            "additionalClassifications": [],
            "unit": {
                "code": "",
                "name": gettext('единицы измерения')
            },
            "classification": {},
            "quantity": "",
            "description": ""
        });
    };

    $scope.removeField = function (index) {
        if ($scope.plan.items.length > 0) {
            $scope.plan.items.splice(index, 1);
        }
    };

    $scope.addBreakDownField = function () {
        $scope.plan.budget.breakdown.push({
            description: "",
            title: "",
            value: {
                amount: null,
                currency: ""
            }
        });
    };

    $scope.removeBreakDownField = function (index) {
        if ($scope.plan.budget.breakdown.length > 0) {
            $scope.plan.budget.breakdown.splice(index, 1);
        }
    };

    $scope.publish = function () {
        $scope.plan.procuringEntity.identifier.legalName = $scope.plan.procuringEntity.name;

        $scope.disablePublishBtn = true;
        $scope.$broadcast('show-errors-check-validity');

        if (!$scope.createPlanForm.$valid) {
            ntFlash.error(gettext('Заповніть обов\'язкові поля (підкреслені червоним)'));
            $scope.disablePublishBtn = false;
            return false;
        }

        if (['belowThreshold', 'reporting', ''].includes($scope.plan.tender.procurementMethodType) && $scope.plan.budget.breakdown[0].title === '') {
            delete $scope.plan.budget.breakdown;
        } else {
            if ($scope.plan.tender.procurementMethodType === 'esco' && $scope.plan.budget.breakdown.some(el => el.value.currency !== 'UAH')) {
                $scope.disablePublishBtn = false;
                ntFlash.error(gettext('Валюта джерел фінансування в процедурі ESCO має бути гривня'));
                return false;
            }

            if ($scope.plan.tender.procurementMethodType !== 'esco' && $scope.plan.budget.breakdown.some(el => el.value.currency !== $scope.plan.budget.currency)) {
                $scope.disablePublishBtn = false;
                ntFlash.error(gettext('Валюта усіх джерел фінансування повинна збігатись з валютою плану'));
                return false;
            }

            let sumBreakDownAmount = 0;
            for (const breakdown of $scope.plan.budget.breakdown) {
                sumBreakDownAmount = sumBreakDownAmount + breakdown.value.amount;
            }
            if ($scope.plan.tender.procurementMethodType !== 'esco' && sumBreakDownAmount > $scope.plan.budget.amount) {
                $scope.disablePublishBtn = false;
                ntFlash.error(gettext('Загальна вартість всіх джерел фінансування не може бути більшим за суму плану'));
                return false;
            }
        }

        if ($scope.plan.classification.id === "") {
            ntFlash.error(gettext('Вкажіть класифікатор'));
            $scope.disablePublishBtn = false;
            return false;
        }

        // if ($scope.startMonth != "") {
        //     $scope.plan.tender.tenderPeriod.startDate = new Date($scope.startYear, $scope.startMonth - 1);
        // }

        // period validation
        if ($scope.plan.budget.period.startDate &&
            new Date($scope.plan.budget.period.startDate) > new Date($scope.plan.budget.period.endDate)) {
            ntFlash.error(gettext('Період вказано не вірно'));
            $scope.disablePublishBtn = false;
            return false;
        }

        if (!validateService.classifiers($scope.plan.items, $scope.plan.classification, $scope.plan.additionalClassifications)) {
            $scope.disablePublishBtn = false;
            return false;
        }


        if ($scope.createPlanForm.$valid) {
            planningService.edit($state.params.id, $scope.plan)
                .then(function (result) {
                    if (result.data.response) {
                        $timeout(function () {
                            $scope.disablePublishBtn = false;
                            $state.go('planning.overview', {
                                id: result.data.response.id
                            }, {
                                reload: true
                            });
                        }, 2000);
                        ntFlash.success(gettext('План успешно изменен. Не забудьте подписать ключом КЕП'));
                    }

                }, function (err) {
                    console.log(err);
                    $scope.disablePublishBtn = false;
                });
        } else {
            $scope.disablePublishBtn = false;
        }
    };

    $scope.cancel = function () {
        $state.go('planning.overview', {
            id: $state.params.id
        });
    };

    $scope.isProcuringEntityChanged = false;
    $scope.notificationProcuringEntity = function () {
        if (!$scope.isProcuringEntityChanged) {
            ntFlash.error(gettext('Увага! Створити Закупівлю з цього Плану зможе тільки вказана організація'));
            $scope.isProcuringEntityChanged = true;
        }
    };

    $scope.$watch('isCzo', (isCzo) => {
        if (isCzo) {
            classifierService.loadCzo()
                .then(function(data) {
                    console.log('loadCzo data', data);
                    $scope.czoList = data.filter(item => item.active);
                    $scope.plan.tender.procurementMethod = '';
                    $scope.plan.tender.procurementMethodType = 'centralizedProcurement';
                    if ($scope.wasCzo) {
                        $scope.plan.procuringEntity = cacheProcuringEntity;
                    } else {
                        $scope.plan.procuringEntity = {
                            identifier: {
                                scheme: "",
                                id: "",
                                legalName: ""
                            },
                            name: "",
                            kind: "",
                            address: ""
                        };
                    }
                })
                .catch(function(resError) {
                    console.log('loadCzo error', resError);
                })
        } else {
            $scope.plan.tender.procurementMethod = 'open';
            if ($scope.wasCzo) {
                $scope.plan.tender.procurementMethodType = '';
                $scope.plan.procuringEntity = {
                    identifier: {
                        scheme: "",
                        id: "",
                        legalName: ""
                    },
                    name: "",
                    kind: "",
                    address: ""
                };
            } else {
                $scope.plan.tender.procurementMethodType = cacheProcurementMethodType;
                $scope.plan.procuringEntity = cacheProcuringEntity;
            }
        }
    });

    $scope.$watch('hideAddress', (hideAddress) => {

        if (hideAddress) {
            delete $scope.plan.buyers[0].address.locality
            delete $scope.plan.buyers[0].address.postalCode
            delete $scope.plan.buyers[0].address.region
            delete $scope.plan.buyers[0].address.streetAddress
        } else {
            $scope.plan.buyers[0].address = angular.copy(angular.copy(OPC_CONFIG.PROCURING_ENTITY).address)
        }
        // console.log($scope.plan.procuringEntity);
        // console.log($scope.plan.buyers[0]);

    });

    $scope.changedCzoSelected = function(czoSelected) {
        if (czoSelected !== null) {
            for (const czoItem of $scope.czoList) {
                if (czoItem.identifier.id === czoSelected) {
                    $scope.plan.procuringEntity = {
                        address: czoItem.address,
                        kind: 'general',
                        name: (czoItem.name) ? czoItem.name : czoItem.name_uk,
                        name_en: (czoItem.name_en) ? czoItem.name_en : czoItem.name_uk,
                        identifier: {
                            id: czoItem.identifier.id,
                            legalName: czoItem.identifier.legalName,
                            scheme: czoItem.identifier.scheme
                        }
                    };

                    if($scope.plan.procuringEntity.active !== undefined){
                        delete $scope.plan.procuringEntity.active;
                    }

                    if($scope.plan.procuringEntity.marketAdministrator !== undefined){
                        delete $scope.plan.procuringEntity.marketAdministrator;
                    }
                    break;
                }                
            }
        }
    }
});
