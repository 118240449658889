export const fuelContractTitles: Record<string, string> = {
    subject: "ПРЕДМЕТ ДОГОВОРУ",
    price: "ЦІНА ДОГОВОРУ",
    paymentOrder: "ПОРЯДОК ОПЛАТИ",
    deliveryTerms: "УМОВИ ПОСТАВКИ",
    guarantee: "ЯКІСТЬ ТА ГАРАНТІЇ",
    acceptanceOrder: "ПОРЯДОК ПОСТАВКИ ТА ПРИЙМАННЯ-ПЕРЕДАЧІ ПАЛИВА",
    rights: "ПРАВА ТА ОБОВ'ЯЗКИ СТОРІН",
    responsibilities: "ВІДПОВІДАЛЬНІСТЬ СТОРІН ЗА ПОРУШЕННЯ УМОВ ДОГОВОРУ",
    disputes: "ВИРІШЕННЯ СПОРІВ",
    forceMajeure: "ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ",
    anticorruption: "АНТИКОРУПЦІЙНІ ЗАСТЕРЕЖЕННЯ",
    messaging: "ПОРЯДОК ЗДІЙСНЕННЯ ПОВІДОМЛЕННЯ СТОРІН",
    signOrder: "ПОРЯДОК ПІДПИСАННЯ ДОКУМЕНТІВ ДО ДОГОВОРУ З ВИКОРИСТАННЯМ ЕЛЕКТРОННИХ ПІДПИСІВ",
    contractAction: "ДІЯ ДОГОВОРУ",
    finalStatements: "ПРИКІНЦЕВІ ПОЛОЖЕННЯ",
    additionals: "ДОДАТКИ, ЩО Є НЕВІД’ЄМНИМИ ЧАСТИНАМИ ДОГОВОРУ",
};
