import {AllVersionFormatter} from "~/widgets/pq/services/contractTextFormatters/AllVersionFormatter";
import {PQContractType} from "~/widgets/pq/types/PQTypes";
import {SecondVersionFormatter} from "~/widgets/pq/services/contractTextFormatters/SecondVersionFormatter";
import {TemplateCodesEnum} from "~/widgets/pq/types/TemplateCodes.enum";
import {IPQBuilder} from "~/widgets/pq/PQBuilderInterface";

export class FoodBuilder implements IPQBuilder {
    build(contractObject: PQContractType | Record<string, never>, contractTemplate: TemplateCodesEnum, tender: any): Record<string, any>[] {
        return [
            AllVersionFormatter.createTitle(contractObject as PQContractType),
            SecondVersionFormatter.createGenericHeader(contractObject as PQContractType),
            AllVersionFormatter.createContractText(contractTemplate, contractObject, tender),
            SecondVersionFormatter.createGenericAddition1(contractObject),
            SecondVersionFormatter.createGenericAddition2(contractObject, tender),
        ];
    }
}
