class ValidateService {
    constructor(
        $filter,
        ntFlash,
        turboMode,
        accelerator
    ) {
        let DAY = turboMode ? 86400000 / accelerator : 86400000;
        const datesValidateModel = {
            'belowThreshold': { enquiryDays: 3, tenderDays: 2, isCalendarDays: false },
            'aboveThresholdUA': { tenderDays: 15, isCalendarDays: true },
            'aboveThreshold': { tenderDays: 7, isCalendarDays: true },
            'competitiveOrdering': { tenderDays: 7, isCalendarDays: true },
            'competitiveDialogueUA.stage2': { tenderDays: 15, isCalendarDays: true },
            'aboveThresholdEU': { tenderDays: 30, isCalendarDays: true },
            'competitiveDialogueUA': { tenderDays: 30, isCalendarDays: true },
            'competitiveDialogueEU': { tenderDays: 30, isCalendarDays: true },
            'competitiveDialogueEU.stage2': { tenderDays: 30, isCalendarDays: true },
            'closeFrameworkAgreementUA': { tenderDays: 30, isCalendarDays: true },
            'esco': { tenderDays: 30, isCalendarDays: true },
            'aboveThresholdUA.defense': { tenderDays: 6, isCalendarDays: false },
            'simple.defense': { tenderDays: 6, isCalendarDays: false },
            'closeFrameworkAgreementSelectionUA': { tenderDays: 3, isCalendarDays: true }
        };

        this.periodValidator = function(startDate = new Date(), endDate, minDays, isCalendarDays = true) {

            // console.log(startDate);
            // console.log(endDate);
            // console.log(minDays);

            if (!startDate || !endDate || !minDays || startDate > endDate) return;
            if (typeof startDate === 'string') startDate = new Date(startDate);

            const timezoneOffset = startDate.getTimezoneOffset() * 60 * 1000;
            const startTime = +startDate - timezoneOffset;
            const endTime = +endDate - timezoneOffset;
            const rest = ((DAY - startTime % DAY) / DAY !== 1) ? ((DAY - startTime % DAY) / DAY) : 0;
            const diff = (endTime - startTime) / DAY;
            const calendarDiff = +(diff - rest).toFixed(4);

            if (isCalendarDays) return calendarDiff >= minDays;

            let currentTime = (+startDate + (rest * DAY)) - 1;
            let lastTime = +endDate;
            let workingDiff = 0;
            if ([0, 6].includes(new Date(currentTime).getDay())) {
                currentTime += 1
            }
            while (currentTime < lastTime) {
                if(turboMode){
                    workingDiff += lastTime - currentTime >= DAY ? 1 : (lastTime - currentTime) / DAY;

                } else {
                    if (![0, 6].includes(new Date(currentTime).getDay())) {

                        workingDiff += lastTime - currentTime >= DAY ? 1 : (lastTime - currentTime) / DAY;
                    }
                }
                currentTime = currentTime + DAY;
            }

            workingDiff = +workingDiff.toFixed(4);
            return workingDiff >= minDays;
        }


        this.date = (dates) => {
            let deliveryDates = dates.deliveryDate;
            dates.deliveryDate = {
                start: null,
                end: null
            };
            deliveryDates.forEach((val) => {
                if (!dates.deliveryDate.start && val.deliveryDate.startDate) {
                    dates.deliveryDate.start = val.deliveryDate.startDate;
                }
                if (!dates.deliveryDate.end && val.deliveryDate.endDate) {
                    dates.deliveryDate.end = val.deliveryDate.endDate;
                }
                if (dates.deliveryDate.start && dates.deliveryDate.start > val.deliveryDate.startDate && val.deliveryDate.startDate) {
                    dates.deliveryDate.start = val.deliveryDate.startDate;
                }
                if (dates.deliveryDate.end && dates.deliveryDate.end < val.deliveryDate.endDate && val.deliveryDate.endDate) {
                    dates.deliveryDate.end = val.deliveryDate.endDate;
                }
                if (val.deliveryDate.startDate && val.deliveryDate.endDate && val.deliveryDate.startDate >= val.deliveryDate.endDate) {
                    ntFlash.error(gettext('Крайний срок периода поставки должен быть позже его начала'));
                    return false;
                }
            });

            for (var key in dates) {
                if (key !== 'deliveryDate') {
                    let dateType = $filter('datesTypeFilter')(key);

                    if (dates[key].end <= dates[key].start &&
                        dates[key].start != "" &&
                        dates[key].end != "" &&
                        dates[key].start != null &&
                        dates[key].end != null) {

                        ntFlash.error(gettext('Крайний срок периода ') + dateType + gettext(' должен быть позже его начала'));
                        return false;
                    }
                }
            }

            if (dates.enquiryPeriod.end > dates.tenderPeriod.start &&
                dates.tenderPeriod.start !== "" &&
                dates.tenderPeriod.start !== null &&
                dates.tenderProcedure === "belowThreshold") {

                ntFlash.error(gettext('Период предложений должен начинаться после окончания периода уточнений'));
                return false;
            }
            if (dates.tenderPeriod.end > dates.deliveryDate.start &&
                dates.deliveryDate.start !== "" &&
                dates.deliveryDate.start !== null) {

                ntFlash.error(gettext('Период поставки должен начинаться после окончания периода предложений'));
                return false;
            }
            if (dates.tenderPeriod.end >= dates.deliveryDate.end &&
                dates.deliveryDate.end !== "" &&
                dates.deliveryDate.end !== null) {

                ntFlash.error(gettext('Период поставки должен начинаться после окончания периода предложений'));
                return false;
            }
            if (dates.tenderPeriod.end <= dates.enquiryPeriod.end &&
                dates.tenderPeriod.end !== "" &&
                dates.tenderPeriod.end !== null &&
                dates.tenderProcedure === "belowThreshold") {

                ntFlash.error(gettext('Период регистрации предложений должен начинаться после окончания периода уточнений'));
                return false;
            }

            const enquiryDays = datesValidateModel[dates.tenderProcedure] && datesValidateModel[dates.tenderProcedure].enquiryDays;
            if (enquiryDays && !this.periodValidator(dates.enquiryPeriod.start, dates.enquiryPeriod.end, enquiryDays,
                                                        datesValidateModel[dates.tenderProcedure].isCalendarDays)
            ) {
                ntFlash.error(gettext('Период уточнений должен быть не менее ') + enquiryDays + ' ' + gettext('дней'));
                return false;
            }

            const tenderDays = datesValidateModel[dates.tenderProcedure] && datesValidateModel[dates.tenderProcedure].tenderDays;
            if (tenderDays && !this.periodValidator(dates.tenderPeriod.start, dates.tenderPeriod.end, tenderDays,
                                                        datesValidateModel[dates.tenderProcedure].isCalendarDays)
            ) {
                ntFlash.error(gettext('Период регистрации предложений должен длиться не менее ') + tenderDays + ' ' + gettext('дней'));
                return false;
            }

            return true;
        };
        this.classifiers = (items, classification, additionalClassifications) => {
            function prefixClassifier(cl) {
                let prefix;
                if (cl) {
                    prefix = cl.substr(0, 4);
                    if (prefix.substr(0, 3) === '336') {
                        prefix = '336';
                    }
                    return prefix;
                }
            }

            let firstClassifier = null;
            let firstDkppClassifier = {};

            let localItems = items ? items.slice() : [];
            
            if (classification || additionalClassifications) {
                let rootItem = {};
                if (classification) {
                    rootItem.classification = classification;
                }
                if (additionalClassifications) {
                    rootItem.additionalClassifications = additionalClassifications;
                }
                localItems.push(rootItem);
            }

            if (localItems && localItems.length > 0) {
                for (let i = 0; i < localItems.length; i++) {
                    if (!localItems[i].classification) {
                        ntFlash.error(gettext('Классификаторы ДК021 обязателен'));
                        return false;

                    } else if (!firstClassifier) {
                        firstClassifier = prefixClassifier(localItems[i].classification.id);
                    } else if (firstClassifier !== localItems[i].classification.id.substr(0, firstClassifier.length)) {
                        ntFlash.error(gettext('Классификаторы ДК021 должны быть из одной группы (первые {{n}} цифры)').replace('{{n}}', firstClassifier.length));
                        return false;
                    }
                    let addClassifications = localItems[i].additionalClassifications || [];
                    if (localItems[i].classification.id === '33600000-6') {
                        let additionalClassificationsINN = addClassifications.filter((cl) => {
                            return cl.scheme === 'INN';
                        });
                        if (additionalClassificationsINN.length === 0) {
                            ntFlash.error(gettext('Для ДК021:33600000-6 классификатор INN обязателен'));
                            return false;
                        }
                    } else {                        
                        for (let k = 0; k < addClassifications.length; k++) {
                         // if (addClassifications[k].scheme !== 'INN' && addClassifications[k].scheme !== 'ACC') {
                            if (!['INN', 'ATC', 'GMDN', 'UA-ROAD'].includes(addClassifications[k].scheme)) {
                                if (!firstDkppClassifier[addClassifications[k].scheme]) {
                                    firstDkppClassifier[addClassifications[k].scheme] = addClassifications[k].id.replace(/\./g, '').substr(0, 5);
                                } else if (firstDkppClassifier[addClassifications[k].scheme] !== addClassifications[k].id.replace(/\./g, '').substr(0, 5)) {
                                    ntFlash.error(gettext('Дополнительные классификаторы должны быть из одной группы (первые 5 цифр)'));
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
            return true;
        };

        this.isValidItemsCpvByCategory = (mainProcurementCategory, items) => {
            if (!mainProcurementCategory) return true;

            let cpvs = [];
            if (Array.isArray(items)) {
                for (let i=0; i < items.length; i++) {
                    if (items[i].classification) {
                        let cpv = parseInt(items[i].classification.id.slice(0,3), 10);
                        if (!isNaN(cpv)) {
                            cpvs.push(cpv);
                        }
                    }
                }
            } else {
                if (items.classification) {
                    let cpv = parseInt(items.classification.id.slice(0,3), 10);
                    if (!isNaN(cpv)) {
                        cpvs.push(cpv);
                    }
                }
            }

            // validate cpv
            for (let i=0; i < cpvs.length; i++) {
                if (cpvs[i] >= 30 && cpvs[i] <= 449) {
                    if (mainProcurementCategory !== 'goods') return false;
                } else if ((cpvs[i] >= 450 && cpvs[i] <= 454) || cpvs[i] === 713) {
                    if (mainProcurementCategory !== 'works') return false;
                } else {
                    if (mainProcurementCategory !== 'services') return false;
                }
            }

            return true;
        };

        this.step = (budget, step) => {
            if (step && (step > budget * 0.03 || step < 0)) {
                ntFlash.error(gettext('Диапазон шага должен быть в пределах от 0 до 3% от суммы плана'));
                return false;
            } else {
                return true;
            }
        };
        this.features = (features) => {
            let isValid = true;
            features.forEach((feature) => {
                let values = [];
                feature.enum.forEach((option) => {
                    if (values.indexOf(option.value) >= 0) {
                        ntFlash.error(gettext('Значения опций показателя должны быть уникальными'));
                        isValid = false;
                    }
                    values.push(option.value);
                });
            });
            return isValid;
        };
        this.lots = (lots, items) => {
            let isValid = true;

            if (!lots) {
                return true;
            }

            lots.forEach((lot) => {
                let lotHasItem = false;
                items.forEach((item) => {
                    if (item.relatedLot === lot.id) {
                        lotHasItem = true;
                    }
                });
                if (!lotHasItem) {
                    ntFlash.error(gettext('Каждый лот должен иметь предмет закупки'));
                    isValid = false;
                }
            });

            return isValid;
        };
        this.lotHasItem = (items) => {
            return (items.length === 0) ? true : false;
        }
        this.regex = {
            email: /^[a-zA-Z0-9\u007F-\uffff!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9\u007F-\uffff!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/,
            phone: /^[0-9\s\+\(\)\-]+$/
        }
    }
}
commons.service('validateService', ValidateService);
