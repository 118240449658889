import {TemplateCodesEnum} from "~/widgets/pq/types/TemplateCodes.enum";

export class TemplateCodeChecker {
    static isExistingTemplateName(templateName: string): boolean {
        return Object.values(TemplateCodesEnum).includes(templateName as TemplateCodesEnum);
    }

    static validatedTemplateName(templateName: string, fallbackTemplate = TemplateCodesEnum.GENERIC): TemplateCodesEnum {
        return TemplateCodeChecker.isExistingTemplateName(templateName) ? templateName as TemplateCodesEnum : fallbackTemplate;
    }
}
