export const pharm2Titles = {
    predmet: "ПРЕДМЕТ ДОГОВОРУ",
    asortiment: "АСОРТИМЕНТ ТА КІЛЬКІСТЬ ТОВАРУ",
    jakist: "ЯКІСТЬ ТОВАРУ",
    tsina: "ЦІНА ДОГОВОРУ ТА ЦІНА ТОВАРУ",
    paymentOrder: "ПОРЯДОК РОЗРАХУНКІВ", // 5
    zasobi: "ЗАСОБИ УПАКОВКИ, МАРКУВАННЯ ТА ФАСУВАННЯ",
    porjadok: "ПОРЯДОК ПОСТАВКИ, ПРИЙМАННЯ-ПЕРЕДАЧА ТОВАРУ",
    vidpovidalnist: "ВІДПОВІДАЛЬНІСТЬ СТОРІН",
    obstavini: "ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ (ФОРС-МАЖОР)",
    virishennja: "ВИРІШЕННЯ СПОРІВ",
    antikoruptsijne: "АНТИКОРУПЦІЙНЕ ЗАСТЕРЕЖЕННЯ",
    sanktsijni: "САНКЦІЙНІ ЗАСТЕРЕЖЕННЯ",
    strok: "СТРОК ДОГОВОРУ",
    vnesennja: "ВНЕСЕННЯ ЗМІН ДО ДОГОВОРУ ТА УМОВИ РОЗІРВАННЯ ДОГОВОРУ",
    konfidentsijna: "КОНФІДЕНЦІЙНА ІНФОРМАЦІЯ",
    inshi: "ІНШІ УМОВИ",
};
